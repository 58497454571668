<script>
import DefaultMixin from '../mixin'
import MenuDash from "components/clientes/components/include/MenuDash"

export default {
  name: 'ClientePageDefault',
  provide: function () {
    return {
      page: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    MenuDash
  },
  data () {
    return {
      menuActive: 'dashboard',
      sleftFolded: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.adjustRoute()
    })
  },
  computed: {
    css () {
      const css = []
      if (this.$route.name === 'clientes.dashboard') css.push('is-dashboard')
      return css
    }
  },
  watch: {
    '$route.name' (v) {
     this.adjustRoute(v)
    }
  },
  methods: {
    adjustRoute (v) {
      if (v && v === 'clientes.dashboard') {
        this.menuActive = 'dashboard'
      } else {
        this.menuActive = this.$route.name
      }
    },
    folded () {
      if (document.body.classList.contains('sleft-folded')) {
        document.body.classList.remove('sleft-folded')
      } else {
        document.body.classList.add('sleft-folded')
      }
    }
  },
  meta: {
    title: 'Suporte',
    name: 'Suporte'
  }
}
</script>

<template>
  <div class="cliente-page" :class="css">
    <div class="cliente-page-container">
      <div class="sleft" id="cliente-page-left" :class="{folded: sleftFolded}">
        <div class="component-opts"></div>
        <div id="cliente-page-menu" ref="menu">
          <menu-dash class="level1" :class="{show: ['clientes.dashboard', 'clientes.lista', 'clientes.anotador'].includes($route.name)}" />
        </div>
        <div class="bar-resize" @click="folded"><i class="fa fa-arrow-left btn-click"/></div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
