<template>
  <div class="smenu">
    <ul>
      <page-menu-item label="Dashboard" icon="fal fa-clipboard-list fa-fw" :route="{name: 'clientes.dashboard'}"/>
      <page-menu-item label="Clientes" icon="fal fa-book fa-fw fa-fw" :route="{name: 'clientes.lista'}"/>
    </ul>
    <div class="menu-lbl lbl-master">Infraestrutura e Segurança</div>
    <ul>
      <page-menu-item label="Servidores" class="disabled" icon="fal fa-server fa-fw"/>
      <page-menu-item label="Backup" class="disabled" icon="fal fa-database fa-fw"/>
    </ul>
    <div class="menu-lbl lbl-master">Monitoramento</div>
    <ul>
      <page-menu-item label="Leilões" class="disabled" icon="fal fa-gavel fa-fw"/>
      <page-menu-item label="Monitoramento" class="disabled" icon="fal fa-desktop fa-fw"/>
    </ul>
    <div class="menu-lbl lbl-master">Business I/A</div>
    <ul>
      <page-menu-item class="disabled" label="Relatórios" icon="fal fa-chart-line fa-fw"/>
    </ul>
    <div class="menu-lbl lbl-master">Marketing & Communication</div>
    <ul>
      <page-menu-item class="disabled" label="Mensagem clientes" icon="fal fa-envelope fa-fw"/>
    </ul>
  </div>
</template>

<script>
import PageMenuItem from "components/clientes/components/include/PageMenuItem"

export default {
  name: "MenuDashClientes",
  inject: ['page'],
  components: {
    PageMenuItem,
  }
}
</script>
